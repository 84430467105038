import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MarkdownViewer from "@components/MarkdownViewer"

const PrivacyPages = ({ data }) => {
  const post = data.allPrivacyPagesJson.nodes[0]

  return (
    <Layout customNavButton={post.customNavButton} footerType={"full"}>
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        schemaPage={null}
      />

      <div className="container privacy-pages">
        <MarkdownViewer markdown={post.content} />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PrivacyPages($title: String!) {
    allPrivacyPagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        content
        customNavButton {
          title
          href
          type
          hasButton
        }
      }
    }
  }
`

export default PrivacyPages
